import moment from 'moment';

/*
 * @param yyyy/MM/DDTHH:mm:ss.sssZ
 * || yyyy/MM/DDTHH:mm:ss+00:00
 * @param format Date
 * @param prefix String
 * return DD/MM/YYYY HH:mm
 */
export const formatDateTime = (
    dateTime,
    format = 'DD/MM/YYYY HH:mm',
    prefix = '',
) => {
    try {
        if (!dateTime) return '';
        if (moment(dateTime).isValid())
            return prefix + moment(dateTime).format(format);
    } catch (error) {
        console.log('Catch formatDateTime', error);
    }
};

export const getDateTimeUtc = (dateTime) => {
    // input format: 2023/03/30 07:30
    // output format: 2023-03-30T00:30:00Z

    const currentDate = moment(dateTime).utc().format();
    return currentDate;
};

export const getYesterDay = (date = new Date()) => {
    const yesterday = new Date(date.getTime());
    yesterday.setDate(date.getDate() - 1);
    return yesterday;
}

export const getTomorrow = (date = new Date()) => {
    const tomorrow = new Date(date.getTime());
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
}

export const getMondayOfWeek = (date = new Date()) => {
    // console.log('getDay', date);
    const currentDay = date.getDay();
    const monday = new Date(date.getTime());
    switch (currentDay) {
        case 0:
            // Sunday
            monday.setDate(date.getDate() - 6);
            break;
        case 1:
            // Monday
            break;
        case 2:
            // Tuesday
            monday.setDate(date.getDate() - 1);
            break;
        case 3:
            // Wednesday
            monday.setDate(date.getDate() - 2);
            break;
        case 4:
            // Thursday
            monday.setDate(date.getDate() - 3);
            break;
        case 5:
            // Friday
            monday.setDate(date.getDate() - 4);
            break;
        case 6:
            // Saturday
            monday.setDate(date.getDate() - 5);
            break;
    
        default:
            break;
    }
    return monday;
}

export const getSundayOfWeek = (date = new Date()) => {
    const currentDay = date.getDay();
    const sunday = new Date(date.getTime());
    switch (currentDay) {
        case 0:
            // Sunday
            break;
        case 1:
            // Monday
            sunday.setDate(date.getDate() + 6);
            break;
        case 2:
            // Tuesday
            sunday.setDate(date.getDate() + 5);
            break;
        case 3:
            // Wednesday
            sunday.setDate(date.getDate() + 4);
            break;
        case 4:
            // Thursday
            sunday.setDate(date.getDate() + 3);
            break;
        case 5:
            // Friday
            sunday.setDate(date.getDate() + 2);
            break;
        case 6:
            // Saturday
            sunday.setDate(date.getDate() + 1);
            break;
    
        default:
            break;
    }
    return sunday;
}

export const calculateDays = (startDate: string, endDate: string): number => {
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);

  // One day in milliseconds, used for calculation
  const oneDay: number =  24 * 60 * 60 * 1000;

  // Add 1 day to make the calculation inclusive
  end.setDate(end.getDate() + 1);

  const diffDays: number = Math.round(Math.abs((start.getTime() - end.getTime()) / oneDay));

  return diffDays;
};

export const compareDates = (date1: string, date2: string): number => {
  const a: moment.Moment = moment(date1);
  const b: moment.Moment = moment(date2);

  if (a.isBefore(b)) return -1; // a is earlier than b
  if (a.isAfter(b)) return 1; // a is later than b
  return 0; // a and b are the same day
}